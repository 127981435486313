import { Route, Switch } from "react-router-dom";
import ContactUs from "./contact-us";
import Disclaimer from "./disclaimer";
import DisclaimerDialog from "./DisclaimerDialog";
import Home from "./home";
import BlogList from "./pages/BlogList";
import Engrace from "./pages/Engrace";
import EngraceVista from "./pages/EngraceVista";
import Project302 from "./pages/Project302";
import Project77 from "./pages/Project77";
import Soulace from "./pages/Soulace";
import SoulTree from "./pages/SoulTree";
import Urbanest from "./pages/Urbanest";
import PrivacyPolicy from "./privacy-policy";
// import BlogDetail from "./pages/BlogDetail";
import BlogParent from "./pages/BlogParent";
import Projects from "./pages/Projects";
import ThankYouContact from "./ThankYouContact";
// useRef
import { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Header } from "./components/index";

import GreenStoreys from "./pages/GreenStoreys";
import IvyCounty from "./pages/IvyCounty";
import SereneHeights from "./pages/SereneHeights";
import TermsAndConditionsPage from "./pages/TermsAndConditions";

export default function App() {
    const [isShowLinks, setIsShowLinks] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(
        localStorage.getItem("showDisclaimer") === "false" ? false : true
    );
    let location = useLocation();

    const handleScroll = (event) => {
        let offsetTop = document.documentElement.scrollTop;
        if (offsetTop > 80) {
            setIsShowLinks(true);
        } else {
            setIsShowLinks(false);
        }
    };

    window.addEventListener("scroll", handleScroll);

    useEffect(() => {
        // document.documentElement.scrollTo(0, 0);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [location]);

    useEffect(() => {
        let refreshTime;
        if (typeof window !== "undefined") {
            refreshTime = localStorage.getItem(
                "modern-spaaces-disclaimer-expiry-time"
            );
        }

        if (!refreshTime || new Date() > new Date(Number(refreshTime))) {
            setShowDisclaimer(true);
        }
    }, []);

    const hideDisclaimer = () => {
        const expiryTime = new Date().setHours(new Date().getHours() + 48);
        localStorage.setItem(
            "modern-spaaces-disclaimer-expiry-time",
            expiryTime.toString()
        );
        setShowDisclaimer(false);
        localStorage.setItem("showDisclaimer", false);
    };

    const closeDisclaimer = () => {
        setShowDisclaimer(false);
        localStorage.setItem("showDisclaimer", "false");
    };

    return (
        <>
            <HelmetProvider>
                <article className="app-container p-0 container-fluid">
                    <Header isShowLinks={isShowLinks}></Header>
                    <DisclaimerDialog
                        show={showDisclaimer}
                        onClose={hideDisclaimer}
                        onCancel={closeDisclaimer}
                    />
                    <Switch>
                        <Route
                            path="/project302"
                            component={Project302}
                        ></Route>
                        <Route path="/project77" component={Project77}></Route>
                        <Route path="/soultree" component={SoulTree}></Route>
                        <Route path="/soulace" component={Soulace}></Route>
                        <Route
                            exact
                            path="/engrace"
                            component={Engrace}
                        ></Route>
                        <Route
                            exact
                            path="/engrace-vista"
                            component={EngraceVista}
                        ></Route>
                        {/* <Route exact path="/onyx" component={Onyx}></Route> */}
                        <Route
                            exact
                            path="/serene-heights"
                            component={SereneHeights}
                        ></Route>
                        <Route
                            exact
                            path="/green-storeys"
                            component={GreenStoreys}
                        />
                        <Route exact path="/ivy-county" component={IvyCounty} />
                        <Route path="/urbanest" component={Urbanest}></Route>
                        <Route path="/contact-us" component={ContactUs}></Route>
                        <Route
                            path="/thank-you-contact"
                            component={ThankYouContact}
                        ></Route>
                        <Route
                            path="/disclaimer"
                            component={Disclaimer}
                        ></Route>
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        ></Route>
                        <Route exact path="/blogs" component={BlogList}></Route>
                        {/* <Route path="/blogs/:id" component={BlogDetail}></Route> */}
                        <Route path="/blogs/:id" component={BlogParent}></Route>
                        <Route path="/projects" component={Projects}></Route>
                        <Route
                            path="/terms-and-conditions"
                            component={TermsAndConditionsPage}
                        />
                        <Route path="/" component={Home}></Route>
                    </Switch>
                </article>
            </HelmetProvider>
        </>
    );
}
