import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogPlottedDevelopment-1.jpeg";
const image2 = "/images/Blogs/BlogPlottedDevelopment-2.jpg";
const image3 = "/images/Blogs/BlogPlottedDevelopment-3.png";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "plottedDevelopment",
        text: "What is Plotted Development?",
    },
    {
        id: "investmentBenefits",
        text: "Benefits of Investing in Plots",
        children: [
            {
                id: "freedomToCustomise",
                text: "Freedom to Customise",
            },
            {
                id: "buildWhenReady",
                text: "Build When You're Ready",
            },
            {
                id: "highAppreciation",
                text: "High Appreciation and Easy to Sell",
            },
            {
                id: "quickDevelopment",
                text: "Quick Development, Lower Risks",
            },
        ],
    },
    {
        id: "ivyCounty",
        text: "Ivy County",
    },
    {
        id: "locationAdvantage",
        text: "Unparalleled Location",
    },
    {
        id: "connectivity",
        text: "Unmatched Connectivity",
    },
    {
        id: "prrIrr",
        text: "Peripheral Ring Road (PRR) & Intermediate Ring Road (IRR)",
    },
    {
        id: "strr",
        text: "Satellite Town Ring Road (STRR): Bridging Towns and Cities",
    },
    {
        id: "cdpRoad",
        text: "Direct Connectivity Through the Proposed 100ft CDP Road",
    },
    {
        id: "metro",
        text: "Metro Accessibility",
    },
    {
        id: "conclusion",
        text: "Conclusion",
    },
    {
        id: "faqs",
        text: "FAQ's (Frequently Asked Questions)",
    },
];

const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};
const blogConstant = BLOGS.PLOTTED_DEVELOPMENT;

const data = {
    slug: blogConstant.slug,
    image: image1,
    imageAlt: blogConstant.title,
    title: blogConstant.title,
    time: blogConstant.time,
    publishedOn: blogConstant.date,
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            {tableOfContents.map(({ id, text }) => (
                <li key={id}>
                    <a
                        href={`#${id}`}
                        onClick={() => window.scrollToElement(`#${id}`)}
                    >
                        {text}
                    </a>
                </li>
            ))}
        </ul>
    ),
    title2: <TitleEl content={tableOfContents[0]} />,
    description21: (
        <>
            Have you ever customised a car to match your personality or
            surprised your family with a made-to-order cake? If you have, you’ll
            know the joy of owning or gifting something truly one of a kind.
            Created uniquely to suit your preferences, with subtle details that
            make it exclusively yours. That feeling goes beyond just owning a
            product; it’s about having something rare. Plotted developments
            offer the unique advantage of designing and building a home tailored
            to your vision. In recent years, more Indians have chosen plotted
            developments because it is one of the easiest ways of owning land
            and investing in real estate. The demand for residential plots is
            steadily rising, especially in southern cities. A
            <a
                href="https://www.hindustantimes.com/real-estate/over-67-people-buy-property-for-self-use-investors-press-the-pause-button-due-to-rising-prices-ficci-anarock-survey-101729245593029.html"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                recent survey{" "}
            </a>
            highlights this growing trend, with about 20% of prospective buyers
            now showing a strong preference for investing in residential plots.
        </>
    ),

    title3: <TitleEl content={tableOfContents[1]} />,

    description31: (
        <>
            A plotted development refers to a community offering plots of
            varying sizes, equipped with essential infrastructure like
            electricity, water connections, sewage systems, trash
            management/recycling, and security. These communities often include
            various amenities, ensuring a well-planned and comfortable living
            environment.
        </>
    ),

    title4: <TitleEl content={tableOfContents[2]} />,
    description41: (
        <>
            Investing in plots offers numerous advantages, making it a popular
            choice in real estate. Here’s why:
        </>
    ),
    list42: [
        {
            label: "Freedom to Customise",
            description: (
                <>
                    Designing your own home means having the freedom to create a
                    space that perfectly matches your budget and taste. Dreaming
                    of a cosy central courtyard? Or private living spaces on a
                    separate floor? Maybe multiple kitchens and living rooms to
                    accommodate your kids and extended families, along with
                    extra car parking? The possibilities are endless when you're
                    in control of your dream space!
                </>
            ),
        },

        {
            label: "Build When You're Ready",
            description: (
                <>
                    Investing in a plot gives you the freedom to plan your dream
                    home on your own terms. With no construction deadlines or
                    immediate commitments, you can take your time to strategise,
                    save, and prepare for the perfect home whenever you’re
                    ready. Owning land means you're in control of the timeline.
                    Start with the investment now and let your plans unfold
                    gradually, whether it’s months or years down the line.
                </>
            ),
        },
        {
            label: <>Peace of Mind</>,
            description: (
                <>
                    Stories of land disputes, legal issues, and shattered dreams
                    from encroached plots or unclear titles are all too
                    common—and understandably cause anxiety. However, opting for
                    a well-planned plotted development can put those concerns to
                    rest. Reputed builders ensure clear titles, thoughtfully
                    designed layouts, excellent amenities, and prime locations,
                    making your investment not just secure but stress-free.
                    <div className="imagepadding">
                        <img
                            src={image2}
                            alt="Peace of Mind"
                            class="image margin"
                        />
                    </div>
                </>
            ),
        },
        {
            label: <>High Appreciation and Easy to Sell</>,
            description: (
                <>
                    Plots are among the most sought-after real estate
                    investments, offering high appreciation and strong returns
                    over time. In rapidly growing cities like Bangalore, the
                    value of land rises consistently due to limited availability
                    and increasing demand, especially in areas with expanding
                    infrastructure and amenities. Unlike constructed properties,
                    plots tend to appreciate faster, ensuring significant
                    returns and financial security for investors. Moreover,
                    their steady value growth and universal demand make plots
                    easy to sell, providing flexibility and peace of mind to
                    both end-users and investors.
                </>
            ),
        },
        {
            label: <>Quick Development, Lower Risks</>,
            description: (
                <>
                    Plots usually involve minimal development, which means
                    projects can be delivered quickly, often within a year. This
                    significantly reduces the risk of delays compared to other
                    real estate projects. While timelines may vary based on the
                    amenities offered within a plotted development, the overall
                    process is faster and simpler. Investing in plots offers not
                    just flexibility and returns but also stability, making it
                    an ideal choice for long-term growth and financial security.
                </>
            ),
        },
        {
            label: <>Simple & Effective Real Estate Investing</>,
            description: (
                <>
                    One of the easiest ways to invest in real estate is by
                    purchasing plots, thanks to their straightforward and
                    hassle-free process. Unlike developed properties, plotted
                    developments typically involve fewer complexities, ensuring
                    a smoother and quicker transaction. They also require a
                    lower initial investment, making them more accessible to a
                    broader range of buyers.
                </>
            ),
        },
    ],

    title5: <TitleEl content={tableOfContents[3]} />,
    description51: (
        <>
            Spread across 50 acres of meticulously planned, RERA-approved land,
            Ivy County offers 658 premium villa plots ranging from 1,200 to
            4,000 sq. ft. Here, you have the perfect canvas to create your dream
            home, surrounded by a seamless fusion of comfort, convenience, and
            lush greenery. Adding to the charm is a sprawling 50,000 sq. ft.
            clubhouse, and luxurious amenities that are designed in harmony with
            the natural landscape.
            <div className="imagepadding">
                <img src={image3} alt="Ivy County" class="image margin" />
            </div>
        </>
    ),

    title6: <TitleEl content={tableOfContents[4]} />,

    description61: (
        <>
            Ivy County is strategically located off Gunjur, Varthur-Sarjapur
            Road, a fast-developing and highly sought-after area. Here’s what
            makes the location exceptional:
        </>
    ),

    list62: [
        {
            label: <>Top-tier International Schools</>,
            description: (
                <>
                    You'll find some of Bangalore's finest international schools
                    within a 5 km radius, including Oakridge International,
                    TISB, Greenwood High, GIIS (Global Indian International
                    School), and Inventure Academy. These top-tier institutions
                    provide world-class education, ensuring your children enjoy
                    shorter commutes and more productive learning hours.
                </>
            ),
        },
        {
            label: <>Upscale Residential Neighbourhood</>,
            description: (
                <>
                    Surrounded by premium communities and neighbourhoods, Ivy
                    County offers a lifestyle that is vibrant yet serene, with
                    excellent social infrastructure including cafes,
                    restaurants, breweries, and shopping centres, all
                    contributing to a fulfilling daily life.
                </>
            ),
        },
    ],

    title7: <TitleEl content={tableOfContents[5]} />,

    title8: <TitleEl content={tableOfContents[6]} />,
    description81: (
        <>
            The upcoming Peripheral Ring Road (PRR) and the realigned
            Intermediate Ring Road (IRR) will enhance travel in Bangalore,
            significantly reducing commute time and enhancing connectivity.
            Residents of Ivy County will directly benefit from improved
            accessibility, significantly increasing the long-term value of their
            property.
        </>
    ),
    title9: <TitleEl content={tableOfContents[7]} />,
    description91: (
        <>
            The Satellite Town Ring Road (STRR) is another transformative
            project enhancing Ivy County’s connectivity. Designed to connect
            Bangalore’s satellite towns, the STRR will offer faster access to
            the Bengaluru International Airport and other key destinations. With
            reduced travel times, this development ensures Ivy County is always
            well-connected, making it an excellent choice for those seeking
            convenience.
        </>
    ),
    title10: <TitleEl content={tableOfContents[8]} />,
    description101: (
        <>
            The proposed 100ft CDP road, which will provide direct access to NH
            648 will be a game changer. This road will link seamlessly to the
            STRR, making accessibility to different areas within the city
            hassle-free.
            <br /> With these multiple connectivity options, Ivy County offers
            residents easy access to Bangalore’s major IT hubs and the
            tranquillity of a premium plotted development. Whether it’s the
            proximity to key areas or the planned road networks like STRR, PRR,
            and the CDP road, Ivy County is designed for those who value both
            convenience and modern living.
        </>
    ),

    title11: <TitleEl content={tableOfContents[9]} />,
    description111: (
        <>
            The project is positioned to leverage both current and upcoming
            metro developments:
        </>
    ),
    list112: [
        {
            label: <>Existing Metro Access</>,
            description: (
                <>
                    The Purple Line metro station in Whitefield connects areas
                    like Hopefarm Channasandra, Kadugodi Tree Park, Pattanduru
                    Agrahara, Sri Sathya Sai Hospital, Nallurhalli, Kundalahalli
                    and many other areas till Challaghatta is already
                    operational, providing an efficient and stress-free transit
                    option for residents who need to commute to various parts of
                    Bangalore.
                </>
            ),
        },
        {
            label: <>Future Metro Expansions</>,
            description: (
                <>
                    With planned metro lines extending connectivity further into
                    Sarjapur and surrounding areas, Ivy County is truly a
                    future-ready address. These expansions promise to make your
                    commutes even faster and more convenient, adding long-term
                    value to the property.
                </>
            ),
        },
    ],
    title12: <TitleEl content={tableOfContents[10]} />,
    description121: (
        <>
            Investing in a plotted development offers the rare opportunity to
            own land and build a home that truly reflects your vision. Whether
            you seek the freedom to design your dream home, financial security
            through high appreciation, or a stress-free investment, plotted
            developments provide a unique combination of flexibility, stability,
            and long-term growth.
            <br />
            Ivy County stands as the perfect example of this
            opportunity—offering premium villa plots in a planned,
            well-connected, and future-ready community. With its strategic
            location, seamless connectivity to major infrastructure projects,
            and top-tier social amenities, Ivy County presents an ideal
            investment for those looking to secure their future in Bangalore’s
            rapidly expanding real estate landscape.
            <br /> Are you dreaming of designing your own home in a premium
            residential community? <br />
            Explore Ivy County today or call us at 88 677 92 555 to know more!
            cation, property type, and borrower's financial profile.
        </>
    ),

    Faq: <TitleEl content={tableOfContents[11]} />,

    faqs: [
        {
            label: "Q1: What Are Plotted Developments?",
            description: [
                <>
                    Plotted developments offer the unique opportunity to build
                    your dream home on your own land within a well-maintained
                    community. These developments provide essential
                    infrastructure like electricity, water, and waste
                    management, along with amenities for a comfortable and
                    fulfilling lifestyle.
                </>,
            ],
        },
        {
            label: "Q2: Where is Ivy County located?",
            description: [
                <>Ivy County is located off Gunjur, Varthur Sarjapur Road</>,
            ],
        },
        {
            label: "Q3: What are the Plot sizes available & land area?",
            description: [
                <>
                    There are a total of 658 plots available, with sizes ranging
                    from 1,200 sq. ft to 4,000 sq. ft. The project spans across
                    an expansive 50 acres of lush green land, providing a
                    perfect blend of space and serenity.
                </>,
            ],
        },
        {
            label: "Q4: What are Ready-to-Register Plots for Sale?",
            description: [
                <>
                    Ivy County offers a diverse range of ready-to-register plots
                    for sale, including 1200 sq ft, 1500 sq ft, 1800 sq ft, 2000
                    sq ft, 2400 sq ft, 3000 sq ft, and 4000 sq ft, providing
                    flexibility to suit various needs and preferences.
                </>,
            ],
        },
        {
            label: "Q5: What about the locality?",
            description: [
                <>
                    Ivy County is located in a premium residential corridor
                    where you’ll find top international schools just within 5
                    km, and it’s perfectly connected to the major IT hubs like
                    Whitefield, Outer Ring Road, and Sarjapur.
                </>,
            ],
        },
        {
            label: "Q6: What are the amenities?",
            description: [
                <>
                    Amenities include a massive clubhouse, home theatre,
                    swimming pool, library, kid’s adventure play area, seniors'
                    pavilion, adventure nature trail, multi-purpose sports
                    court, the Tree of Life, overlooking yoga deck, Forest
                    Grove, flower garden, and community garden.
                </>,
            ],
        },
        {
            label: "Q7: How big is the clubhouse?",
            description: [<>The size of the clubhouse is 50,000 sq.ft.</>],
        },
        {
            label: "Q7:  How do I book my plot?",
            description: [
                <>
                    To book your plot, simply give us a call at 88 677 92 555.
                    Our team will assist you and help schedule a site visit to
                    choose the perfect plot for you.
                </>,
            ],
        },
    ],
};

export default data;
